.list-page {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background: linear-gradient(178.07deg, #d4c710 -9.74%, #fcf192 82.69%);
  background-clip: border-box;
  border: 1px solid rgba(214, 214, 214, 0.125);
  border-radius: 0.25rem;
  padding: 2em;
  min-height: 700px;
}

.description {
  margin: 0;
  font-family: "Chakra Petch";
  font-weight: 500;
  font-size: 20px;
  color: #000;
  user-select: none;
}

.game-container {
  display: flex;
  flex-wrap: wrap;
  margin: 2em auto;
  width: 80%;
  min-height: 350px;
}

.game-card {
  color: #fff;
  padding: 0.2em 1em;
  margin: auto;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  border-radius: 10px;
  border: 2px solid rgb(156, 155, 155);
  box-shadow: 5px 5px 10px 2px #3f3c3ca1;
  background: #211d79b8;
  cursor: pointer;
  word-break: break-all;
  user-select: none;
  width: 15em;
  min-height: 3em;
  max-height: 5em;
}

.page-container {
  margin: 0.2em auto;
  color: #000;
  user-select: none;
}

.custom-page-btn {
  padding: 0.3em 1em;
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  transition: all 0.5s;
  border: 2px solid;
  background: linear-gradient(351.09deg, #f029a0 4.36%, #f0b000 117.5%);
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  border-radius: 25px;
  overflow: hidden;
  user-select: none;
}

.search-input-container {
  margin: 1em auto;
  width: 20%;
}

.search-input {
  padding: 0.8em;
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgb(156, 155, 155);
}

@media screen and (max-width: 767px) {
  .game-card {
    max-height: 6em;
  }

  .game-container {
    display: block;
  }

  .game-container {
    height: auto !important;
  }

  .search-input-container {
    width: 80% !important;
  }
}
