.run-page {
  overflow: hidden;
  user-select: none;
  background: linear-gradient(178.07deg, #d4c710 -9.74%, #fcf192 82.69%);
}

.run-page .screen-container {
  text-align: center;
  margin-bottom: 2em;
}

.game-title-center {
  font-family: "Chakra Petch";
  color: #000;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.custom-btn {
  margin: 0 0.5em;
  padding: 0.3em 1em;
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  transition: all 0.5s;
  border: 2px solid;
  background: linear-gradient(351.09deg, #f029a0 4.36%, #f0b000 117.5%);
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  border-radius: 25px;
  overflow: hidden;
  user-select: none;
}

.header-container {
  margin: 1em;
  align-items: center;
}

.right-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
