.header {
  background-color: #484049;
  color: #fff;
  padding: 1em 2em;
  cursor: default;
}

.title {
  font-family: "Chakra Petch";
  color: #fff;
  text-transform: uppercase;
  user-select: none;
  font-weight: 700;
  padding: 0.5em 0;
}

.title h1 {
  line-height: 1.2;
  margin: 1.5em 0 1em;
}

.header nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.header nav li {
  display: inline-block;
  padding: 0;
}

.header nav div {
  color: #fff;
  text-decoration: none;
  padding: 14px 16px;
  cursor: pointer;
  font-size: 25px;
}

.header nav div:hover {
  background-color: #fff;
  color: #333 !important;
  cursor: pointer;
}

@media (max-width: 768px) {
  .header .title {
    font-family: "Chakra Petch";
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    user-select: none;
  }
  nav {
    text-align: left;
  }
  nav ul {
    display: none;
  }
  nav li {
    margin-right: 0;
    margin-bottom: 10px;
  }
  nav a {
    display: block;
  }
}
