.footer {
  padding-left: 1em;
  padding-right: 1em;
  position: relative;
  overflow: hidden;
  padding: 1em 0 1em 0;
  background: #2b252c;
  color: #fff;
  border-radius: 0.2em;
  user-select: none;
}

a {
  text-decoration: none !important;
}

.label {
  color: #ced120;
}

.footer ul {
  display: flex;
  flex-wrap: wrap;
}

.footer ul li {
  list-style: circle;
  width: 15em;
}

.footer li a {
  text-align: center;
  border-radius: 100%;
  color: inherit;
  font-size: 20px;
}

.footer header {
  margin: 0 0 1em 0;
  margin: auto;
}

.footer-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 1em;
}

.footer .copyright {
  text-align: center;
  color: rgba(201, 198, 198, 0.75);
  font-size: 0.8em;
  cursor: default;
}

.footer .copyright a {
  color: rgba(128, 128, 128, 0.75);
}

.footer .copyright a:hover {
  color: rgba(212, 212, 212, 0.85);
}
