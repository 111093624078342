.modal-dialog {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background: linear-gradient(178.07deg, #d4c710 -9.74%, #fcf192 82.69%);
  background-clip: border-box;
  border-radius: 20px;
  padding: 1em;
}

.modal-content {
  background: none !important;
  border: 0 !important;
}

.modal-header {
  border: 0 !important;
}

.table {
  font-size: 20px !important;
}

.table td,
.table th {
  border-top: 1px solid rgb(156, 155, 155) !important;
}

.table tbody + tbody {
  border: 0 !important;
}

.modal-footer {
  border-top: 1px solid rgb(156, 155, 155) !important;
}

.custom-btn {
  padding: 0.3em 1em;
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  transition: all 0.5s;
  border: 2px solid;
  background: linear-gradient(351.09deg, #f029a0 4.36%, #f0b000 117.5%);
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  border-radius: 25px;
  overflow: hidden;
  user-select: none;
}
