.controller-container {
  margin-top: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

/** 下準備_ボタン **/
.btn {
  border-style: none !important;
  cursor: pointer !important;
}

/** 下準備_十字配置部品 **/
.cross-layout {
  display: grid !important;
  grid-template-columns: 35px 35px 35px !important;
  grid-template-rows: 35px 35px 35px !important;
}

.cross-layout .position-top {
  grid-row: 1 / 2 !important;
  grid-column: 2 / 3 !important;
}

.cross-layout .position-left {
  grid-row: 2 / 3 !important;
  grid-column: 1 / 2 !important;
}

.cross-layout .position-center {
  grid-row: 2 / 3 !important;
  grid-column: 2 / 3 !important;
}

.cross-layout .position-right {
  grid-row: 2 / 3 !important;
  grid-column: 3/4 !important;
}

.cross-layout .position-bottom {
  grid-row: 3 / 4 !important;
  grid-column: 2/3 !important;
}

/**　コントローラーの右側_ABXYボタン**/
.abxy-btn {
  width: 35px !important;
  height: 35px !important;
  border-radius: 50% !important;
  color: black !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.btn-x {
  background-color: blue !important;
}

.btn-y {
  background-color: green !important;
}

.btn-a {
  background-color: red !important;
}

.btn-b {
  background-color: yellow !important;
}

/**　コントローラーの右側_ABXYボタンを灰色の円形で囲む**/
.abxy-btn-set {
  display: inline-block !important;
  padding: 10px !important;
  background-color: rgba(66, 86, 123, 0.5) !important;
  border-radius: 50% !important;
}

/**　コントローラーの右側_ABXYボタンを灰色の円形で囲んだものをさらに灰色の円形で囲む**/
.controller-right {
  display: inline-block !important;
  background-color: rgb(229, 227, 250) !important;
  padding: 15px !important;
  border-radius: 50% !important;
}

/**　コントローラーの左側_十字キー**/
.cross-key-btn {
  width: 35px !important;
  height: 35px !important;
  background-color: rgba(66, 86, 123, 0.5) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.left-mark {
  display: block !important;
  user-select: none !important;
  transform: rotate(-90deg) !important;
}

.right-mark {
  display: block !important;
  user-select: none !important;
  transform: rotate(90deg) !important;
}

.bottom-mark {
  display: block !important;
  user-select: none !important;
  transform: rotate(180deg) !important;
}

/**　コントローラーの左側_十字キーを灰色の円形で囲む**/
.controller-left {
  display: inline-block !important;
  background-color: rgb(229, 227, 250) !important;
  padding: 25px !important;
  border-radius: 50% !important;
}

/**　コントローラーの真ん中_スタートボタン(セレクトボタン)**/
.selectstart-btn {
  width: 10px !important;
  height: 20px !important;
  border-radius: 15% !important;
  background-color: rgba(66, 86, 123, 0.5) !important;
  transform: rotate(10deg) !important;
}

/**　コントローラーの真ん中_スタートボタンとセレクトボタンを並べる**/
.selectstart-btn:first-child {
  margin-right: 10px !important;
}

/**　コントローラーの真ん中_Logoを書く領域を定義**/
.logo-msg {
  font-family: "Mina", sans-serif !important;
  user-select: none !important;
}

/**　コントローラーの真ん中_スタートボタン・セレクトボタンとLogoを書く領域を一つにまとめる**/
.controller-center {
  width: 320px !important;
  height: 130px !important;
  background-color: rgb(229, 227, 250) !important;
}

.controller-center .logo-msg {
  display: block !important;
  width: 140px !important;
  margin: auto !important;
  padding-top: 15px !important;
  text-align: center !important;
  font-size: 0.8em !important;
  color: gray !important;
}

.controller-center .selectstart-btn-set {
  width: 140px !important;
  text-align: center !important;
  margin: auto !important;
  margin-top: 40px !important;
}

/**　コントローラーの左側と真ん中と右側をくっつける**/
.spf-controller {
  display: flex !important;
}

.spf-controller .controller-center {
  margin-left: -80px !important;
  margin-right: -80px !important;
}

.spf-controller .controller-center {
  z-index: 1 !important;
}

.spf-controller .controller-left {
  z-index: 2 !important;
}

.spf-controller .controller-right {
  z-index: 2 !important;
}
