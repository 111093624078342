
.main-title {
  font-size: 2em !important;
  font-family: "proxima_novabold";
  font-weight: bold;
  margin: 1.5em 0 1em;
}

.sub-title {
  font-size: 1.7em !important;
  font-family: "proxima_novabold";
  font-weight: bold;
  margin: 1.5em 0 1em;
}

.sub-sub-title {
  font-size: 1.3em !important;
  font-family: "proxima_novabold";
  font-weight: bold;
  margin: 1.5em 0 1em;
}

p {
  font-size: 1.2em !important;
  line-height: 1.5em;
  margin: 1em 0;
}
