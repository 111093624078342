.error-page-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background: linear-gradient(178.07deg, #d4c710 -9.74%, #fcf192 82.69%);
  background-clip: border-box;
  border: 1px solid rgba(214, 214, 214, 0.125);
  border-radius: 0.25rem;
  padding: 1.5em;
  min-height: 700px;
}

.error-description {
  margin: auto;
  font-family: "Chakra Petch";
  font-weight: 400;
  font-size: 27px;
  line-height: 27px;
  color: #000;
}